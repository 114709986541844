import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../../components/layouts/layout"
import HeadMeta from "../../components/layouts/headmeta"
import ProgressBar from "../../components/layouts/progressbar"
import Reviews from "../../components/reviews"
import Faq from "../../components/faq"
import HowItWorks from "../../components/how-it-works"
import { changeProgressBarOnScroll } from "../../components/utils/index"
import CTA from "../../components/cta"

export default class DNAExplorerPrimePage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Sales", "DNA Explorer Prime")
  }

  render() {
    return (
      <Layout>
        <div className="product-page" id="dna-explorer">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero hero-landing bg-gray"
            id="dna-explorer-prime"
            style={{
              maxHeight: "90vh",
              backgroundPosition: "center",
            }}
          >
            <div className="background-tint tint-light d-flex align-items-center">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-6 offset-lg-6 text-center text-md-right order-2 order-lg-1 p-4">
                    <img
                      className="img-fluid"
                      src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                      style={{ maxWidth: "200px" }}
                      alt="DNA Explorer Prime"
                    />
                    <h1>Have better control over your health</h1>
                    <p>
                      Discover more about your body.
                      <br /> Personalise your diet and fitness routines.
                      <br /> Practice a healthy lifestyle based on your DNA.
                    </p>
                    <a
                      className="btn btn-explorer-yellow"
                      href="https://app.advanxhealth.com/products/overview?product=34"
                    >
                      Get It Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="about" className="about">
            <div className="container">
              <div className="text-center">
                <h2>
                  Take Control Of Your Health With{" "}
                  <img
                    className="img-fluid"
                    src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                    style={{ maxWidth: "250px" }}
                    alt="DNA Explorer Prime"
                  />
                </h2>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <img
                    src="/img/product/newbox.png"
                    className="img-fluid"
                    alt="Product box"
                  />
                </div>
                <div className="col-md-8">
                  <p className="lead" style={{ fontSize: "1.4rem" }}>
                    Your DNA + Your Lifestyle = Your Health.
                  </p>

                  <p className="lead mt-3">
                    Blood tests, health checkups, these are all tools that can
                    tell us what our current health condition is. What about
                    your genetic predispositions?
                    <br />
                    <br />
                    Get a comprehensive understanding of yourself, by getting
                    insights into the way your DNA influences your nutrition,
                    fitness training, hidden potentials and health risks.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="extra-features" className="extra-features bg-white pt-0">
            <div className="container text-center">
              <header>
                <div className="row my-4">
                  <div className="col-md-10 offset-md-1">
                    <h2 className="text-center">
                      What's Included In{" "}
                      <img
                        className="img-fluid"
                        src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                        style={{ maxWidth: "250px" }}
                        alt="DNA Explorer Prime"
                      />
                    </h2>
                  </div>
                  <div className="col-md-6 offset-md-3">
                    <p className="text-center">
                      Get access to 124 comprehensive insights into your
                      predispositions for health conditions and nutrition,
                      fitness, inner potentials, allergy, and weight management
                      traits.
                    </p>
                  </div>
                </div>
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-5 row">
                      <div className="col-md-4">
                        <img
                          src={withPrefix(
                            "/img/illustrations/prime-healthrisk.svg"
                          )}
                          className="img-fluid px-1 pb-3"
                          alt="Health risks"
                        />
                        <h5>Health Risks</h5>
                      </div>
                      <div className="col-md-4">
                        <img
                          src={withPrefix(
                            "/img/illustrations/prime-allergy.svg"
                          )}
                          className="img-fluid px-1 pb-3"
                          alt="Allergy & Sensitivity"
                        />
                        <h5>Allergy</h5>
                      </div>
                      <div className="col-md-4">
                        <img
                          src={withPrefix("/img/illustrations/prime-skin.svg")}
                          className="img-fluid px-1 pb-3"
                          alt="Skin"
                        />
                        <h5>Skin</h5>
                      </div>
                    </div>
                    <div className="col-md-1">
                      <h2 className="text-explorer-yellow">
                        <i className="fa fa-plus"></i>
                      </h2>
                    </div>
                    <div className="col-md-6 row">
                      <div className="col-md-3">
                        <img
                          src={withPrefix(
                            "/img/illustrations/prime-nutrition.svg"
                          )}
                          className="img-fluid px-1 pb-3"
                          alt="Nutrition"
                        />
                        <h5>Nutrition</h5>
                      </div>
                      <div className="col-md-3">
                        <img
                          src={withPrefix(
                            "/img/illustrations/prime-fitness.svg"
                          )}
                          className="img-fluid px-1 pb-3"
                          alt="Fitness"
                        />
                        <h5>Fitness</h5>
                      </div>
                      <div className="col-md-3">
                        <img
                          src={withPrefix(
                            "/img/illustrations/prime-innerpotential.svg"
                          )}
                          className="img-fluid px-1 pb-3"
                          alt="Inner potential"
                        />
                        <h5>Inner Potential</h5>
                      </div>
                      <div className="col-md-3">
                        <img
                          src={withPrefix(
                            "/img/illustrations/prime-weightmanagement.svg"
                          )}
                          className="img-fluid px-1 pb-3"
                          alt="Weight Management"
                        />
                        <h5>Weight Management</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </header>
              <hr />
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/prime-healthrisk.svg")}
                    className="img-fluid p-5"
                    alt="Health Risks"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Health Risks</h2>
                  <p>32 reports on health predispositions based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Atopic Dermatitis / Eczema</li>
                        <li>Age-Related Macular Degeneration</li>
                        <li>Perennial Allergic Rhinitis</li>
                        <li>Asthma</li>
                        <li>Attention Deficit Hyperactivity Disorder (ADHD)</li>
                        <li>Chronic Kidney Disease (CKD)</li>
                        <li>Coronary Artery Disease</li>
                        <li>Glaucoma</li>
                        <li>Gout</li>
                        <li>Height</li>
                        <li>Hypertension</li>
                        <li>Hypertriglyceridemia</li>
                        <li>Longevity</li>
                        <li>Lumbar Disc Disease / Back Pain</li>
                        <li>Migraine</li>
                        <li>Nicotine Dependence</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Non-Alcoholic Fatty Liver Disease</li>
                        <li>Osteoarthritis</li>
                        <li>Osteoporosis</li>
                        <li>Parkinson's Disease</li>
                        <li>Stroke</li>
                        <li>Systemic Lupus Erythematosus (SLE)</li>
                        <li>Type 2 Diabetes</li>
                        <li>Adiponectin Levels</li>
                        <li>Ageing</li>
                        <li>Alzheimer's Disease</li>
                        <li>C Reactive Protein (CRP)</li>
                        <li>Familial Hypercholesterolemia</li>
                        <li>Glucose Level During Fasting</li>
                        <li>High-Density Lipoproteins (HDL)</li>
                        <li>Inflammation</li>
                        <li>Obesity</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/prime-allergy.svg")}
                    className="img-fluid p-5"
                    alt="Allergy & Sensitivity"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Allergy & Sensitivity Traits</h2>
                  <p>
                    14 reports on allergy and sensitivity traits based on your
                    DNA.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Allergy to Cats</li>
                        <li>Allergy to Dust Mites</li>
                        <li>Allergy to Egg</li>
                        <li>Allergy to Peanuts</li>
                        <li>Caffeine Metabolism</li>
                        <li>Gluten Sensitivity</li>
                        <li>Hay Fever</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Histamines Intolerance</li>
                        <li>Lactose intolerance</li>
                        <li>Motion sickness</li>
                        <li>Sensitivity to Benzene</li>
                        <li>Sensitivity To Bitter Taste</li>
                        <li>Sensitivity To Saltiness</li>
                        <li>Sensitivity To Sweetness</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/prime-nutrition.svg")}
                    className="img-fluid p-5"
                    alt="Nutrition"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Nutrition Traits</h2>
                  <p>12 reports on nutrition requirements based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Vitamin A Requirement</li>
                        <li>Vitamin B12 Requirement</li>
                        <li>Vitamin B6 Requirement</li>
                        <li>Vitamin C Requirement</li>
                        <li>Vitamin D Requirement</li>
                        <li>Vitamin E Requirement</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Vitamin K Requirement</li>
                        <li>Calcium Requirement</li>
                        <li>Folic Acid Requirement</li>
                        <li>Iodine Requirement</li>
                        <li>Iron Requirement</li>
                        <li>Omega-6 Requirement</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix(
                      "/img/illustrations/prime-weightmanagement.svg"
                    )}
                    className="img-fluid p-5"
                    alt="Weight Management"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Weight Management Traits</h2>
                  <p>17 reports on weight management based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Carbohydrate Sensitivity</li>
                        <li>Carbohydrates Overconsumptions</li>
                        <li>Effect Of Diet On The Weight Control</li>
                        <li>Effect Of Exercise On The Weight Control</li>
                        <li>Effect Of Protein On Weight Control</li>
                        <li>Fat Sensitivity</li>
                        <li>Fat Storage</li>
                        <li>Fat Taste Perception</li>
                        <li>Low-Fat Diet Effectiveness</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Mediterranean Diet Effectiveness</li>
                        <li>Metabolic Circadian Rhythm</li>
                        <li>Metabolism Rate</li>
                        <li>Monounsaturated Fats Increased Benefits</li>
                        <li>Overweight Potential</li>
                        <li>Risk From Saturated Fats</li>
                        <li>Satiety Regulation</li>
                        <li>Snacking</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/prime-fitness.svg")}
                    className="img-fluid p-5"
                    alt="Fitness"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Fitness Traits</h2>
                  <p>
                    21 reports on fitness potential and recovery ability based
                    on your DNA.
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Achilles Tendon Protection</li>
                        <li>Aerobic Exercise Capacity</li>
                        <li>Aerobic Potential</li>
                        <li>Anaerobic Exercise Capacity</li>
                        <li>Body Temperature During Exercise</li>
                        <li>Caffeine Sensitivity</li>
                        <li>Glucose Response To Exercise</li>
                        <li>Joint Injury</li>
                        <li>Lactate Threshold</li>
                        <li>Lactate Transport</li>
                        <li>Lean Body Mass</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Ligament Protection</li>
                        <li>Maximum Oxygen Uptake</li>
                        <li>Muscle Cell Mitochondrial Function</li>
                        <li>Muscle Soreness</li>
                        <li>Muscular Endurance</li>
                        <li>Muscular Power</li>
                        <li>Muscular Strength</li>
                        <li>Pain Tolerance</li>
                        <li>Sports Recovery Ability</li>
                        <li>Stress Fracture</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix("/img/illustrations/prime-skin.svg")}
                    className="img-fluid p-5"
                    alt="Skin"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Skin Traits</h2>
                  <p>15 reports on your skin based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Skin Ageing</li>
                        <li>Wrinkle</li>
                        <li>Skin Elasticity</li>
                        <li>Antioxidant</li>
                        <li>Collagen Breakdown</li>
                        <li>Skin Glycation</li>
                        <li>Eyelid sagging</li>
                        <li>Inflammatory Cytokines</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Hydration </li>
                        <li>Freckles</li>
                        <li>UV Protection</li>
                        <li>Sun Tanning</li>
                        <li>Acne Vulgaris</li>
                        <li>Dermal Sensitivity </li>
                        <li>Stretch Mark (Striae distensae)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-4">
                <div className="col-md-6">
                  <img
                    src={withPrefix(
                      "/img/illustrations/prime-innerpotential.svg"
                    )}
                    className="img-fluid p-5"
                    alt="Inner potential"
                  />
                </div>
                <div className="col-md-6 text-left">
                  <h2>Inner Potential Traits</h2>
                  <p>13 reports on inner potential based on your DNA.</p>
                  <div className="row">
                    <div className="col-md-6">
                      <ul>
                        <li>Ability to Handle Stress</li>
                        <li>Ability to Memorise</li>
                        <li>Absolute Pitch</li>
                        <li>Creativity Ability</li>
                        <li>Alcohol Flush</li>
                        <li>Appetite Regulation</li>
                        <li>Cognitive Ability</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul>
                        <li>Reading ability</li>
                        <li>Deep sleep</li>
                        <li>Language Development</li>
                        <li>Learning Ability</li>
                        <li>Post-Traumatic Stress Disorder</li>
                        <li>Tendency to stay up late</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-gradient-pink-orange">
            <Reviews />
          </section>
          <section className="how-it-works pt-4">
            <HowItWorks />
          </section>
          <section className="p-0 p-md-5">
            <div
              className="container bg-explorer-yellow py-5 my-0"
              style={{ borderRadius: ".5rem" }}
            >
              <div className="row text-white align-items-center">
                <div className="col-md-1 offset-md-2">
                  <span className="text-left">
                    <i
                      className="fa fa-quote-left mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
                <div className="col-md-6">
                  <h3 className="text-white text-center ">
                    Your DNA loads the gun,
                    <br className="" /> your lifestyle pulls the trigger.
                  </h3>
                </div>
                <div className="col-md-1 text-right text-md-left">
                  <span className="">
                    <i
                      className="fa fa-quote-right mb-4"
                      style={{ fontSize: "2.5rem" }}
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section className="mb-0 bg-light" id="what-you-get">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-2 order-md-1 text-center text-md-center">
                  <img
                    className="img-fluid"
                    src={withPrefix("img/logo/DNA-Explorer-Prime.png")}
                    alt="DNA Explorer Prime"
                  />
                  <h4>
                    Get comprehensive reports
                    <br /> on 124 traits, <br />
                    based on your unique DNA.
                  </h4>
                  <a
                    href="https://app.advanxhealth.com/products/overview?product=34"
                    className="btn btn-explorer-yellow mt-4 btn-lg"
                  >
                    Get It Now
                  </a>
                </div>
                <div className="col-md-6 order-1 order-md-2">
                  <img
                    className="d-md-block product-gif"
                    src={withPrefix("/img/gifs/new-dashboard.gif")}
                    alt="Sample report"
                  />
                </div>
              </div>
            </div>
          </section>
          <section id="cta" className="cta bg-warning-light">
            <div className="container">
              <div className="row align-items-center my-5">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-center">Explore Your DNA Today.</h2>
                  <p>
                    Uncover your genetic predispositions. Manage your lifestyle
                    with personalised recommendations.
                  </p>
                </div>
              </div>
              <CTA />
            </div>
          </section>
          <section id="faq" className="py-5">
            <Faq />
          </section>
          <section className="py-5">
            <div className="container">
              <div className="row mt-4">
                <div className="col-md-4 text-center d-flex align-items-center">
                  <img src="/img/logo.png" className="img-fluid" alt="logo" />
                </div>
                <div className="col-md-8">
                  <h2 className="mb-4">
                    We want to empower you to take charge of your health.
                  </h2>
                  <p className="mx-auto">
                    First, we want to help you start by fully understanding your
                    current health condition. Once you have a complete 360degree
                    view of your health and wellnes, it becomes easier for you
                    to identify the areas that requires improvement.
                  </p>
                  <p className="mx-auto">
                    Working towards a healthier lifestyle is not an easy
                    journey, especially with the vast information out there
                    catered towards all kinds of health/wellbeing.
                    <br /> Every individual is different, which is why our
                    reports will contain personalised information just for you.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Explorer Prime | Advanx Health",
    description:
      "Get access to 124 comprehensive insights into your predispositions for health conditions and nutrition, fitness, inner potentials, allergy, and weight management traits.",
    image: "https://www.advanxhealth.com/img/cool-man.png",
    url: "https://www.advanxhealth.com/products/dna-explorer-prime",
  },
}
